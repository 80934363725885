import React, { Fragment, useRef } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { Menu } from "primereact/menu";

export const AppTopbar = (props) => {
    const { isLoggedIn } = props;
    
    const menu1 = useRef(null);

    const doLogout = () => {
        sessionStorage.clear();
        window.location = "/";
    }

    return (
        <div className="layout-topbar">
            <Link to="/" className="layout-topbar-logo">
                <img src={props.layoutColorMode === "light" ? "assets/layout/images/logo.svg" : "assets/layout/images/logo.svg"} alt="logo" />
                {/* <span>U4U</span> */}
            </Link>

            {isLoggedIn && (
                <Fragment>
                    <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                        <i className="pi pi-bars" />
                    </button>

                    <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                        <i className="pi pi-ellipsis-v" />
                    </button>

                    <ul className={classNames("layout-topbar-menu lg:flex origin-top", { "layout-topbar-menu-mobile-active": props.mobileTopbarMenuActive })}>
                        <li>
                            <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
                                <i className="pi pi-calendar" />
                                <span>Events</span>
                            </button>
                        </li>
                        <li>
                            <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
                                <i className="pi pi-cog" />
                                <span>Settings</span>
                            </button>
                        </li>
                        <li>
                            <button
                                className="p-link layout-topbar-button"
                                onClick={(event) => {
                                    props.onMobileSubTopbarMenuClick(event);
                                    menu1.current.toggle(event);
                                }}
                            >
                                <i className="pi pi-user" />
                                <Menu
                                    ref={menu1}
                                    popup
                                    model={[
                                        { label: "Logout", icon: "pi pi-fw pi-minus", command:()=>{doLogout()}}
                                    ]}
                                />

                                <span>Profile</span>
                            </button>
                        </li>
                    </ul>
                </Fragment>
            )}
        </div>
    );
};
