import RestClient from "RestClient";

const restClient = new RestClient();

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
// const BASE_URL = "http://localhost:4000";
export const getAll = async () => {
    const result = await restClient.get(`${BASE_URL}/blogs`, []);
    return result?.data;
};

export const add = async (payload) => {
    const result = await restClient.post(`${BASE_URL}/blogs/create`, payload);
    return result?.data;
};

export const update = async (id, payload) => {
    const result = await restClient.put(`${BASE_URL}/blogs/${id}`, payload);
    return result?.data;
};

export const deleteRecord = async (id) => {
    const result = await restClient.delete(`${BASE_URL}/blogs/${id}`);
    return result?.data;
};

export const getSingle = async (payload) => {
    const result = await restClient.get(`${BASE_URL}/blogs/${payload}`);
    return result?.data;
};

export const getBySlug = async (payload) => {
    const result = await restClient.get(`${BASE_URL}/blogs/view?slug=${payload}`);
    return result?.data;
};

export const getByName = async (payload) => {
    const result = await restClient.get(`${BASE_URL}/blogs/`, payload);
    return result?.data;
};

export const postImages = async (payload) => {
    const result = await restClient.post(`http://localhost:4000/blogs/upload`, payload);
    return result?.data;
};
