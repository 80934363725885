import { MOCK_USERS } from "mocks/mock";
import moment from "moment";
import { getAllRoles } from "pages/Roles/roles.service";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ConfirmationDialogBox from "utils/ConfirmationDialogBox";
import { ErrorToastConfig } from "utils/ToastConstants";
import { SuccessToastConfig } from "utils/ToastConstants";
import { deleteUser, getAllUsers, getUser } from "./users.service";

const Users = () => {
    const [users, setUsers] = useState(null);
    const skillSet = JSON.parse(sessionStorage.getItem("skillSet"));
    const [isLoading, setIsLoading] = useState(false);
    const [globalFilter, setGlobalFilter] = useState("");
    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [userId, setUserId] = useState("");
    const [totalResumes, setTotalResumes] = useState(0);

    const dt = useRef(null);
    const toast = useRef();
    const showSuccess = (message) => {
        toast.current.show(SuccessToastConfig(message));
    };
    const showError = (message) => {
        toast.current.show(ErrorToastConfig(message ? message : "Error"));
    };

    
    const header = (
        <div className="flex align-items-center export-buttons">
            <Button type="button" icon="pi pi-file" onClick={() => exportCSV(false)} className="mr-2" data-pr-tooltip="CSV" /> Export
        </div>
    );

    const tableHeader = (
        <div className="table-header">
            List of Users
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
            </span>
            <div>
                {header}
            </div>
        </div>
    );

    const bodyTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}
            </>
        );
    };

    const dateTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {moment(data[props.field]).format("DD-MM-YYYY HH:MM A")}
            </>
        );
    };

    const fullNameTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                <span>{data?.first_name + " " + data?.last_name}</span>
            </>
        );
    };

    const resumeBodyTemplate = (data) => {
        return (
            <>
                <span className="p-column-title">Resume</span>
                <span className={`customer-badge status`}>{data.resume_path}</span>
            </>
        );
    };

    const handleConfirmation = async () => {
        const isDeleted = await deleteUser(userId);
        if (isDeleted) {
            const findDeletedIndex = users.findIndex((user) => user.id === userId);
            users.splice(findDeletedIndex, 1);
            setUserId("");
            showSuccess("Record deleted successfully");
        } else {
            showError("Something went wrong.");
        }
        setDisplayConfirmation(false);
    };

    const handleCancelConfirmation = () => {
        setDisplayConfirmation(false);
    };

    const handleDownloadResume = async (id) => {
        const record = await getUser(id);
        if (record) {
            const win = window.open(record?.resumePath, "_blank");
            win.focus();
        }
    };

    const handleTotalUsers = async () => {
        setIsLoading(true);
        const fetchUsers = async () => {
            const userList = await getAllUsers();
            setUsers(userList);
            const resumeList = userList.filter((user) => user.resume_path !== null);
            setTotalResumes(resumeList.length);
            setIsLoading(false);
        };
        // return () => {
        //     cleanup
        // }
        fetchUsers();
    };

    const handleResumeUsers = async () => {
        setIsLoading(true);
        const resumeList = users.filter((user) => user.resume_path !== null);
        setUsers(resumeList);
        setIsLoading(false);
    };

    const actionBodyTemplate = (data) => {
        return (
            <>
                {data?.resume_path && (
                    <span className={`customer-badge edit`}>
                        {/* <Link to="/formlayout">Delete</Link> */}
                        <Button
                            label="Donwload Resume"
                            onClick={() => {
                                // setDisplayConfirmation(true);
                                handleDownloadResume(data.user_id);
                            }}
                            className="p-button"
                        />
                    </span>
                )}
            </>
        );
    };

    useEffect(() => {
        setIsLoading(true);
        const fetchUsers = async () => {
            const userList = await getAllUsers();
            let newUserList = [];
            if (userList) {
                userList.forEach((ul) => {
                    let newSkills = [];
                    ul.user_skill_maps.forEach((uskill) => {
                        const newSkill = skillSet.find((sk) => sk.skill_id === uskill.skill_set_id);
                        if (newSkill) {
                            newSkills.push(newSkill.skill_set_name);
                        }
                    });
                    if (newSkills) {
                        ul.skillSets = newSkills.toString();
                    }
                    newUserList.push(ul);
                });
            }
            setUsers(newUserList);
            const resumeList = userList.filter((user) => user.resume_path !== null);
            setTotalResumes(resumeList.length);
            setIsLoading(false);
        };
        // return () => {
        //     cleanup
        // }
        fetchUsers();
    }, []);



    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    }

    return (
        <Fragment>
            <Toast ref={toast} className="ToastMessage" />
            {isLoading && (
                <div className="spinner">
                    <ProgressSpinner />
                </div>
            )}
            <div className="grid table-demo">
                <div className="col-12">
                    <div className="card">
                        <div className="grid mb-2">
                            <div className="col-12 lg:col-6 xl:col-6">
                                <div className="card mb-0" onClick={handleTotalUsers} style={{ cursor: "pointer" }}>
                                    <div className="flex justify-content-between mb-3">
                                        <div>
                                            <span className="block text-500 font-medium mb-3">Total Users Count</span>
                                            <div className="text-900 font-medium text-xl">{users && users.length}</div>
                                        </div>
                                        <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                            <i className="pi pi-shopping-cart text-blue-500 text-xl" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 lg:col-6 xl:col-6">
                                <div className="card mb-0" onClick={handleResumeUsers} style={{ cursor: "pointer" }}>
                                    <div className="flex justify-content-between mb-3">
                                        <div>
                                            <span className="block text-500 font-medium mb-3">Total Resumes</span>
                                            <div className="text-900 font-medium text-xl">{totalResumes}</div>
                                        </div>
                                        <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                            <i className="pi pi-map-marker text-orange-500 text-xl" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr />
                        {/* <h5>Customized</h5>
                    <p>
                        Scrollable table with gridlines (<mark>.p-datatable-gridlines</mark>), striped rows (<mark>.p-datatable-striped</mark>) and smaller paddings (<mark>p-datatable-sm</mark>).
                    </p> */}
                        <DataTable
                            value={users}
                            ref={dt}
                            scrollable
                            scrollDirection="both"
                            scrollHeight="600px"
                            className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                            rows={10}
                            dataKey="id"
                            paginator
                            rowHover
                            globalFilter={globalFilter}
                            emptyMessage="No users found."
                            loading={isLoading}
                            header={tableHeader}
                        >
                            <Column style={{ flexGrow: 1, flexBasis: "200px" }} field="fullname" header="Name" sortable body={fullNameTemplate}></Column>
                            <Column style={{ flexGrow: 1, flexBasis: "400px" }} field="email" header="Email" sortable body={bodyTemplate}></Column>
                            <Column style={{ flexGrow: 1, flexBasis: "400px" }} field="phone_number" header="Phone" sortable body={bodyTemplate}></Column>
                            <Column style={{ flexGrow: 1, flexBasis: "400px" }} field="skillSets" header="Skills" sortable body={bodyTemplate}></Column>
                            <Column style={{ flexGrow: 1, flexBasis: "200px" }} field="created_on" header="Date" sortable body={dateTemplate}></Column>
                            <Column style={{ flexGrow: 1, flexBasis: "200px" }} field="" header="Actions" sortable body={actionBodyTemplate}></Column>
                        </DataTable>
                    </div>
                </div>

                {displayConfirmation && <ConfirmationDialogBox handleCancelConfirmation={handleCancelConfirmation} handleConfirmation={handleConfirmation} displayConfirmation={displayConfirmation} />}
            </div>
        </Fragment>
    );
};

export default Users;
