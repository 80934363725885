import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { statusList } from "utilities/utility.service";
import { ErrorToastConfig } from "utils/ToastConstants";
import { SuccessToastConfig } from "utils/ToastConstants";
import { add, getSingle, postImages, update, getBySlug } from "../blog.service";
import { FileUpload } from "primereact/fileupload";
import { getExtension } from "utilities/utility.service";
import { Editor } from "primereact/editor";

const initialVales = {
    id: "",
    title: "",
    short_description: "",
    description: "",
    image_path: "",
    images: "",
    status: "",
    metaTitle: null,
    metaDescription: null,
    metaKeywords: null,
};

const AddBlog = () => {
    const { id } = useParams();
    const [values, setValues] = useState(initialVales);
    const [isLoading, setIsLoading] = useState(false);
    const toast = useRef();
    const history = useHistory();
    const showSuccess = (message) => {
        toast.current.show(SuccessToastConfig(message));
    };
    const showError = (message) => {
        toast.current.show(ErrorToastConfig(message ? message : "Error"));
    };

    const fetchCurrentRecord = async () => {
        setIsLoading(true);
        const record = await getSingle(id);
        const newRecord = { ...record };
        // delete newRecord.description;
        setValues(newRecord);
        setIsLoading(false);
    };

    useEffect(() => {
        if (id) {
            fetchCurrentRecord();
        }
    }, [id]);

    const handleInputChange = (e) => {
        if (e.htmlValue) {
            setValues({
                ...values,
                description: e.htmlValue,
            });
        } else {
            setValues({
                ...values,
                [e.target.name]: e.target.value,
            });
        }
    };

    const statuses = statusList();

    const multipleUploader = (event) => {
        if (event.files.length > 4) {
            // event.files.preventDefault();
            showError("You can upload only 4 Files");
            alert("You can upload only 4 Files");
            event.options.clear();
        } else {
            setValues({
                ...values,
                associated_image: event.files,
            });
        }
    };

    const myUploader = (event, name) => {
        setValues({
            ...values,
            [name]: event.files[0],
            [`imageName`]: event.files[0].name,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // if (values?.question_type === "Image") {
        setIsLoading(true);
        let formData = new FormData();
        // console.log(Object.keys(values));
        // Object.keys((values)=>{
        // Object.keys(values).forEach((value) => {
        //     console.log(value);
        //     // if (values[value] !== "") {
        //         formData.append(value, values[value]);
        //     // }
        // });
        // if (values?.question_type === "Image") {
        //     values?.option1 && formData.append("images", values?.option1, `IMG_TRAINING_QUESTION_${uniqueNumber}_OPTION_1.${getExtension(values?.option1Name)}`);
        //     values?.option2 && formData.append("images", values?.option2, `IMG_TRAINING_QUESTION_${uniqueNumber}_OPTION_2.${getExtension(values?.option2Name)}`);
        //     values?.option3 && formData.append("images", values?.option3, `IMG_TRAINING_QUESTION_${uniqueNumber}_OPTION_3.${getExtension(values?.option3Name)}`);
        //     values?.option4 && formData.append("images", values?.option4, `IMG_TRAINING_QUESTION_${uniqueNumber}_OPTION_4.${getExtension(values?.option4Name)}`);

        //     formData.append("option1", `IMG_TRAINING_QUESTION_${uniqueNumber}_OPTION_1.${getExtension(values?.option1Name)}`);
        //     formData.append("option2", `IMG_TRAINING_QUESTION_${uniqueNumber}_OPTION_2.${getExtension(values?.option2Name)}`);
        //     formData.append("option3", `IMG_TRAINING_QUESTION_${uniqueNumber}_OPTION_3.${getExtension(values?.option3Name)}`);
        //     formData.append("option4", `IMG_TRAINING_QUESTION_${uniqueNumber}_OPTION_4.${getExtension(values?.option4Name)}`);
        // } else {
        //     formData.append("option1", values?.option1);
        //     formData.append("option2", values?.option2);
        //     formData.append("option3", values?.option3);
        //     formData.append("option4", values?.option4);
        // }
        // let associatedImagesArray = [];
        // if (values?.associated_image !== "") {
        //     let count = 5;
        //     for (const key of Object.keys(values?.associated_image)) {
        //         formData.append("images", values?.associated_image[key], `IMG_TRAINING_QUESTION_${uniqueNumber}_OPTION_${count}.${getExtension(values?.associated_image[key].name)}`);
        //         associatedImagesArray.push(`IMG_TRAINING_QUESTION_${uniqueNumber}_OPTION_${count}.${getExtension(values?.associated_image[key].name)}`);
        //         ++count;
        //     }
        // }

        const slug = values?.title?.replace("?", "").trim();
        // formData.append( ...values );
        formData.append("title", values?.title?.trim());
        formData.append("slug", slug.replace(/\s/g, "-").toLowerCase());
        formData.append("short_description", values?.short_description);
        formData.append("description", values?.description);
        formData.append("status", values?.status);
        formData.append("metaDescription", values?.metaDescription);
        formData.append("metaKeywords", values?.metaKeywords);
        formData.append("metaTitle", values?.metaTitle);
        if (values?.imageName) {
            let newImageName = values?.imageName.replace(/\s/g, "-").toLowerCase();
            formData.append("image_path", `${newImageName}`);
            formData.append("images", values?.images, `${newImageName}`);
        } else {
            formData.append("image_path", `${values?.image_path}`);
        }
        // if (associatedImagesArray.length > 0) {
        //     formData.append("associated_image", associatedImagesArray.toString());
        // }

        // })
        if (id) {
            const updatedRecord = await update(id, formData);
            setValues(updatedRecord);
            setIsLoading(false);
            if (updatedRecord.status === false) {
                showError(updatedRecord?.message);
            } else {
                showSuccess("Record updated succssfully");
                history.push("/blogs");
            }
        } else {
            const addedRecord = await add(formData);
            setIsLoading(false);
            if (addedRecord.status === false) {
                showError(addedRecord?.message);
            } else {
                showSuccess("Record added succssfully");
                history.push("/blogs");
            }
        }
        // const addRecord = await postImages(formData);
        // const config = {
        //     headers: { "content-type": "multipart/form-data" },
        // };
        // } else {
        //     setIsLoading(true);
        //     if (id) {
        //         if (values.associated_image === null ? (values.associated_image = "") : values.associated_image);
        //         const updatedRecord = await update(id, values);
        //         setIsLoading(false);
        //         setValues(updatedRecord);
        //         if (updatedRecord.status === false) {
        //             showError(updatedRecord?.message);
        //         } else {
        //             showSuccess("Record updated succssfully");
        //         }
        //     } else {
        //         const addedRecord = await add(values);
        //         setIsLoading(false);
        //         if (addedRecord.status === false) {
        //             showError(addedRecord?.message);
        //         } else {
        //             showSuccess("Record added succssfully");
        //         }
        //         history.push("/questionbanks");
        //     }
        // }
    };

    return (
        <Fragment>
            {isLoading && (
                <div className="spinner inner-spinner">
                    <ProgressSpinner />
                </div>
            )}
            <Toast ref={toast} className="ToastMessage" />
            <div className="mb-4">
                <Link to="/blogs">
                    <Button label="Back" className="p-button-sm p-button-success" />
                </Link>
            </div>
            {!isLoading && (
                <form method="POST" onSubmit={handleSubmit} autoComplete="Off">
                    <div className="grid">
                        <div className="col-12 md:col-6">
                            <div className="card p-fluid">
                                <h5>{id ? "Edit Blog" : "Add Blog"}</h5>
                                <div className="field">
                                    <label htmlFor="title">Title*</label>
                                    <InputText id="title" name="title" type="text" required={true} onChange={(e) => handleInputChange(e)} placeholder="Title" value={values?.title} />
                                </div>
                                <div className="field">
                                    <label htmlFor="short_description">Short Description*</label>

                                    <InputTextarea id="short_description" name="short_description" type="text" required={true} onChange={(e) => handleInputChange(e)} placeholder="Short Description" value={values?.short_description} maxLength={255} />
                                </div>
                                {/* {values?.description && ( */}
                                <div className="field">
                                    <label htmlFor="description">Description*</label>

                                    <Editor name="description" required={true} style={{ height: "320px" }} value={values?.description} onTextChange={(e) => handleInputChange(e)} />
                                </div>

                                <div className="field">
                                    <label htmlFor="metaTitle">Meta Title*</label>
                                    <InputText id="metaTitle" name="metaTitle" type="text" required={true} onChange={(e) => handleInputChange(e)} placeholder="Meta Title" value={values?.metaTitle} />
                                </div>
                                <div className="field">
                                    <label htmlFor="metaKeywords">Meta Keywords*</label>
                                    <InputText id="metaKeywords" name="metaKeywords" type="text" required={true} onChange={(e) => handleInputChange(e)} placeholder="Meta Keywords" value={values?.metaKeywords} />
                                </div>
                                <div className="field">
                                    <label htmlFor="metaDescription">Meta Description*</label>

                                    <InputTextarea id="metaDescription" name="metaDescription" type="text" required={true} onChange={(e) => handleInputChange(e)} placeholder="Meta Description" value={values?.metaDescription} />
                                </div>
                                {/* )} */}
                                <div className="field">
                                    <label htmlFor="option1">Image*</label>
                                    <div className="flex">
                                        <FileUpload name="image" mode="basic" customUpload uploadHandler={(event) => myUploader(event, "images")} required auto accept="image/*" />
                                        {/* <input type="file" name="option1" onChange={(event) => myUploader(event, "option1")} /> */}
                                        {!values?.image_path && (
                                            <label className="ml-4 mt-2">
                                                <strong>{values?.imageName}</strong>
                                            </label>
                                        )}
                                        {values?.image_path && <img className="ml-4" src={`https://hrhappyconnects.interosys.com/fetchBlogImages/${values?.image_path}`} style={{ width: "auto", height: "100px" }} />}
                                    </div>
                                    {/* <InputText id="option1" name="option1" type="text" required={true} onChange={(e) => handleInputChange(e)} placeholder="option1" value={values?.option1} /> */}
                                </div>

                                <div className="field">
                                    <label htmlFor="status">Status*</label>
                                    <Dropdown id="status" name="status" value={values?.status} options={statuses} optionLabel="status" optionValue="status" placeholder="Select Status" required={true} onChange={(e) => handleInputChange(e)}></Dropdown>
                                </div>
                                <div>
                                    <Button label="Save" type="submit" />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </Fragment>
    );
};

export default AddBlog;
