import React, { useState, useEffect, Fragment } from "react";
import classNames from "classnames";
import { Route } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import { AppTopbar } from "./AppTopbar";
import { AppFooter } from "./AppFooter";
import { AppMenu } from "./AppMenu";
import { AppConfig } from "./AppConfig";

import { Dashboard } from "./components/Dashboard";
import { ButtonDemo } from "./components/ButtonDemo";
import { ChartDemo } from "./components/ChartDemo";
import { Documentation } from "./components/Documentation";
import { FileDemo } from "./components/FileDemo";
import { FloatLabelDemo } from "./components/FloatLabelDemo";
import { FormLayoutDemo } from "./components/FormLayoutDemo";
import { InputDemo } from "./components/InputDemo";
import { ListDemo } from "./components/ListDemo";
import { MenuDemo } from "./components/MenuDemo";
import { MessagesDemo } from "./components/MessagesDemo";
import { MiscDemo } from "./components/MiscDemo";
import { OverlayDemo } from "./components/OverlayDemo";
import { PanelDemo } from "./components/PanelDemo";
import { TableDemo } from "./components/TableDemo";
import { TreeDemo } from "./components/TreeDemo";
import { InvalidStateDemo } from "./components/InvalidStateDemo";

import { Crud } from "./pages/Crud";
import { EmptyPage } from "./pages/EmptyPage";
import { TimelineDemo } from "./pages/TimelineDemo";
import Login from "./auth/Login";

import PrimeReact from "primereact/api";

import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./layout/flags/flags.css";
import "./layout/layout.scss";
import "./App.scss";
import { useDispatch, useSelector } from "react-redux";
import { getLoggedInUserInformation, storeLoggedInformation } from "./auth/authSlice";
import Users from "pages/Users/Users";
import AddUser from "pages/Users/AddUser/AddUser";
import Blogs from "pages/Blogs/Blog";
import AddBlog from "pages/Blogs/AddBlog/AddBlog";

const App = () => {
    const [layoutMode, setLayoutMode] = useState("static");
    const [layoutColorMode, setLayoutColorMode] = useState("light");
    const [inputStyle, setInputStyle] = useState("outlined");
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);

    PrimeReact.ripple = true;

    let menuClick = false;
    let mobileTopbarMenuClick = false;
    const dispatch = useDispatch();
    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    const loggedInUserInformation = useSelector(getLoggedInUserInformation);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode);
    };

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode);
    };

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    };

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === "overlay") {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            } else if (layoutMode === "static") {
                setStaticMenuInactive((prevState) => !prevState);
            }
        } else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const onSidebarClick = () => {
        menuClick = true;
    };

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    };

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    };

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    };
    const isDesktop = () => {
        return window.innerWidth >= 992;
    };

    const menu = [
        {
            label: "Home",
            items: [
                {
                    label: "Dashboard",
                    icon: "pi pi-fw pi-home",
                    to: "/",
                },
            ],
        },
        {
            label: "Masters",
            icon: "pi pi-fw pi-sitemap",
            items: [
                { label: "Users", icon: "pi pi-fw pi-id-card", to: "/users" },
                { label: "Blogs", icon: "pi pi-fw pi-home", to: "/blogs" },
            ],
        },

        // {
        //     label: "UI Kit",
        //     icon: "pi pi-fw pi-sitemap",
        //     items: [
        //         { label: "Form Layout", icon: "pi pi-fw pi-id-card", to: "/formlayout" },
        //         { label: "Input", icon: "pi pi-fw pi-check-square", to: "/input" },
        //         { label: "Float Label", icon: "pi pi-fw pi-bookmark", to: "/floatlabel" },
        //         { label: "Invalid State", icon: "pi pi-fw pi-exclamation-circle", to: "invalidstate" },
        //         { label: "Button", icon: "pi pi-fw pi-mobile", to: "/button" },
        //         { label: "Table", icon: "pi pi-fw pi-table", to: "/table" },
        //         { label: "List", icon: "pi pi-fw pi-list", to: "/list" },
        //         { label: "Tree", icon: "pi pi-fw pi-share-alt", to: "/tree" },
        //         { label: "Panel", icon: "pi pi-fw pi-tablet", to: "/panel" },
        //         { label: "Overlay", icon: "pi pi-fw pi-clone", to: "/overlay" },
        //         { label: "Menu", icon: "pi pi-fw pi-bars", to: "/menu" },
        //         { label: "Message", icon: "pi pi-fw pi-comment", to: "/messages" },
        //         { label: "File", icon: "pi pi-fw pi-file", to: "/file" },
        //         { label: "Chart", icon: "pi pi-fw pi-chart-bar", to: "/chart" },
        //         { label: "Misc", icon: "pi pi-fw pi-circle-off", to: "/misc" },
        //     ],
        // },
        // {
        //     label: "Pages",
        //     icon: "pi pi-fw pi-clone",
        //     items: [
        //         { label: "Crud", icon: "pi pi-fw pi-user-edit", to: "/crud" },
        //         { label: "Timeline", icon: "pi pi-fw pi-calendar", to: "/timeline" },
        //         { label: "Empty", icon: "pi pi-fw pi-circle-off", to: "/empty" },
        //     ],
        // },
        // {
        //     label: "Menu Hierarchy",
        //     icon: "pi pi-fw pi-search",
        //     items: [
        //         {
        //             label: "Submenu 1",
        //             icon: "pi pi-fw pi-bookmark",
        //             items: [
        //                 {
        //                     label: "Submenu 1.1",
        //                     icon: "pi pi-fw pi-bookmark",
        //                     items: [
        //                         { label: "Submenu 1.1.1", icon: "pi pi-fw pi-bookmark" },
        //                         { label: "Submenu 1.1.2", icon: "pi pi-fw pi-bookmark" },
        //                         { label: "Submenu 1.1.3", icon: "pi pi-fw pi-bookmark" },
        //                     ],
        //                 },
        //                 {
        //                     label: "Submenu 1.2",
        //                     icon: "pi pi-fw pi-bookmark",
        //                     items: [
        //                         { label: "Submenu 1.2.1", icon: "pi pi-fw pi-bookmark" },
        //                         { label: "Submenu 1.2.2", icon: "pi pi-fw pi-bookmark" },
        //                     ],
        //                 },
        //             ],
        //         },
        //         {
        //             label: "Submenu 2",
        //             icon: "pi pi-fw pi-bookmark",
        //             items: [
        //                 {
        //                     label: "Submenu 2.1",
        //                     icon: "pi pi-fw pi-bookmark",
        //                     items: [
        //                         { label: "Submenu 2.1.1", icon: "pi pi-fw pi-bookmark" },
        //                         { label: "Submenu 2.1.2", icon: "pi pi-fw pi-bookmark" },
        //                         { label: "Submenu 2.1.3", icon: "pi pi-fw pi-bookmark" },
        //                     ],
        //                 },
        //                 {
        //                     label: "Submenu 2.2",
        //                     icon: "pi pi-fw pi-bookmark",
        //                     items: [
        //                         { label: "Submenu 2.2.1", icon: "pi pi-fw pi-bookmark" },
        //                         { label: "Submenu 2.2.2", icon: "pi pi-fw pi-bookmark" },
        //                     ],
        //                 },
        //             ],
        //         },
        //     ],
        // },
        // {
        //     label: "Get Started",
        //     items: [
        //         {
        //             label: "Documentation",
        //             icon: "pi pi-fw pi-question",
        //             command: () => {
        //                 window.location = "#/documentation";
        //             },
        //         },
        //         {
        //             label: "View Source",
        //             icon: "pi pi-fw pi-search",
        //             command: () => {
        //                 window.location = "https://github.com/primefaces/sakai-react";
        //             },
        //         },
        //     ],
        // },
    ];

    const digitalMenu = [
        {
            label: "Home",
            items: [
                {
                    label: "Dashboard",
                    icon: "pi pi-fw pi-home",
                    to: "/",
                },
            ],
        },
        {
            label: "Masters",
            icon: "pi pi-fw pi-sitemap",
            items: [
                { label: "Blogs", icon: "pi pi-fw pi-home", to: "/blogs" },
            ],
        },

        // {
        //     label: "UI Kit",
        //     icon: "pi pi-fw pi-sitemap",
        //     items: [
        //         { label: "Form Layout", icon: "pi pi-fw pi-id-card", to: "/formlayout" },
        //         { label: "Input", icon: "pi pi-fw pi-check-square", to: "/input" },
        //         { label: "Float Label", icon: "pi pi-fw pi-bookmark", to: "/floatlabel" },
        //         { label: "Invalid State", icon: "pi pi-fw pi-exclamation-circle", to: "invalidstate" },
        //         { label: "Button", icon: "pi pi-fw pi-mobile", to: "/button" },
        //         { label: "Table", icon: "pi pi-fw pi-table", to: "/table" },
        //         { label: "List", icon: "pi pi-fw pi-list", to: "/list" },
        //         { label: "Tree", icon: "pi pi-fw pi-share-alt", to: "/tree" },
        //         { label: "Panel", icon: "pi pi-fw pi-tablet", to: "/panel" },
        //         { label: "Overlay", icon: "pi pi-fw pi-clone", to: "/overlay" },
        //         { label: "Menu", icon: "pi pi-fw pi-bars", to: "/menu" },
        //         { label: "Message", icon: "pi pi-fw pi-comment", to: "/messages" },
        //         { label: "File", icon: "pi pi-fw pi-file", to: "/file" },
        //         { label: "Chart", icon: "pi pi-fw pi-chart-bar", to: "/chart" },
        //         { label: "Misc", icon: "pi pi-fw pi-circle-off", to: "/misc" },
        //     ],
        // },
        // {
        //     label: "Pages",
        //     icon: "pi pi-fw pi-clone",
        //     items: [
        //         { label: "Crud", icon: "pi pi-fw pi-user-edit", to: "/crud" },
        //         { label: "Timeline", icon: "pi pi-fw pi-calendar", to: "/timeline" },
        //         { label: "Empty", icon: "pi pi-fw pi-circle-off", to: "/empty" },
        //     ],
        // },
        // {
        //     label: "Menu Hierarchy",
        //     icon: "pi pi-fw pi-search",
        //     items: [
        //         {
        //             label: "Submenu 1",
        //             icon: "pi pi-fw pi-bookmark",
        //             items: [
        //                 {
        //                     label: "Submenu 1.1",
        //                     icon: "pi pi-fw pi-bookmark",
        //                     items: [
        //                         { label: "Submenu 1.1.1", icon: "pi pi-fw pi-bookmark" },
        //                         { label: "Submenu 1.1.2", icon: "pi pi-fw pi-bookmark" },
        //                         { label: "Submenu 1.1.3", icon: "pi pi-fw pi-bookmark" },
        //                     ],
        //                 },
        //                 {
        //                     label: "Submenu 1.2",
        //                     icon: "pi pi-fw pi-bookmark",
        //                     items: [
        //                         { label: "Submenu 1.2.1", icon: "pi pi-fw pi-bookmark" },
        //                         { label: "Submenu 1.2.2", icon: "pi pi-fw pi-bookmark" },
        //                     ],
        //                 },
        //             ],
        //         },
        //         {
        //             label: "Submenu 2",
        //             icon: "pi pi-fw pi-bookmark",
        //             items: [
        //                 {
        //                     label: "Submenu 2.1",
        //                     icon: "pi pi-fw pi-bookmark",
        //                     items: [
        //                         { label: "Submenu 2.1.1", icon: "pi pi-fw pi-bookmark" },
        //                         { label: "Submenu 2.1.2", icon: "pi pi-fw pi-bookmark" },
        //                         { label: "Submenu 2.1.3", icon: "pi pi-fw pi-bookmark" },
        //                     ],
        //                 },
        //                 {
        //                     label: "Submenu 2.2",
        //                     icon: "pi pi-fw pi-bookmark",
        //                     items: [
        //                         { label: "Submenu 2.2.1", icon: "pi pi-fw pi-bookmark" },
        //                         { label: "Submenu 2.2.2", icon: "pi pi-fw pi-bookmark" },
        //                     ],
        //                 },
        //             ],
        //         },
        //     ],
        // },
        // {
        //     label: "Get Started",
        //     items: [
        //         {
        //             label: "Documentation",
        //             icon: "pi pi-fw pi-question",
        //             command: () => {
        //                 window.location = "#/documentation";
        //             },
        //         },
        //         {
        //             label: "View Source",
        //             icon: "pi pi-fw pi-search",
        //             command: () => {
        //                 window.location = "https://github.com/primefaces/sakai-react";
        //             },
        //         },
        //     ],
        // },
    ];

    const addClass = (element, className) => {
        if (element.classList) element.classList.add(className);
        else element.className += " " + className;
    };

    const removeClass = (element, className) => {
        if (element.classList) element.classList.remove(className);
        else element.className = element.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
    };

    const wrapperClass = classNames("layout-wrapper", {
        "layout-overlay": layoutMode === "overlay",
        "layout-static": layoutMode === "static",
        "layout-static-sidebar-inactive": staticMenuInactive && layoutMode === "static",
        "layout-overlay-sidebar-active": overlayMenuActive && layoutMode === "overlay",
        "layout-mobile-sidebar-active": mobileMenuActive,
        "p-input-filled": inputStyle === "filled",
        "p-ripple-disabled": ripple === false,
        "layout-theme-light": layoutColorMode === "light",
    });

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const sessionInformation = JSON.parse(sessionStorage.getItem("userInfo"));
    useEffect(() => {
        if (loggedInUserInformation && loggedInUserInformation?.id) {
            setIsLoggedIn(true);
        }
        if (sessionInformation && sessionInformation.id) {
            setIsLoggedIn(true);
        }
    }, [loggedInUserInformation]);
    return (
        <Fragment>
            <div className={wrapperClass} onClick={onWrapperClick}>
                <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode} mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} isLoggedIn={isLoggedIn} />
                <div class="wrapperContainer">
                    {isLoggedIn && sessionInformation.role_id === 1 && (
                        <div className="layout-sidebar" onClick={onSidebarClick}>
                            <AppMenu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} isLoggedIn={isLoggedIn} />
                        </div>
                    )}

                    {isLoggedIn && sessionInformation.role_id === 2 && (
                        <div className="layout-sidebar" onClick={onSidebarClick}>
                            <AppMenu model={digitalMenu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} isLoggedIn={isLoggedIn} />
                        </div>
                    )}

                    {!isLoggedIn && (
                        <div className="">
                            <div className="container-fluid flex justify-content-center align-items-center">
                                <div className="layout-logIn">
                                    <Route path="/" exact component={Login} />
                                    <Route path="/login" exact component={Login} />
                                    {/* <Route path="/signup/:id" exact component={Signup} /> */}
                                    <AppFooter layoutColorMode={layoutColorMode} />
                                </div>
                            </div>
                        </div>
                    )}

                    {isLoggedIn && sessionInformation.role_id === 1 && (
                        <div className="layout-main-container">
                            <div className="layout-main">
                                <Route path="/" exact component={Dashboard} />
                                <Route path="/dashboard" exact component={Dashboard} />
                                <Route path="/formlayout" exact component={FormLayoutDemo} />
                                <Route path="/input" exact component={InputDemo} />
                                <Route path="/floatlabel" exact component={FloatLabelDemo} />
                                <Route path="/invalidstate" exact component={InvalidStateDemo} />
                                <Route path="/button" exact component={ButtonDemo} />
                                <Route path="/table" exact component={TableDemo} />
                                <Route path="/list" exact component={ListDemo} />
                                <Route path="/tree" exact component={TreeDemo} />
                                <Route path="/panel" exact component={PanelDemo} />
                                <Route path="/overlay" exact component={OverlayDemo} />
                                <Route path="/menu" exact component={MenuDemo} />
                                <Route path="/messages" exact component={MessagesDemo} />
                                <Route path="/file" exact component={FileDemo} />
                                <Route path="/chart" exact component={ChartDemo} />
                                <Route path="/misc" exact component={MiscDemo} />
                                <Route path="/timeline" exact component={TimelineDemo} />
                                <Route path="/crud" exact component={Crud} />
                                <Route path="/empty" exact component={EmptyPage} />
                                <Route path="/documentation" exact component={Documentation} />
                                <Route path="/users" exact component={Users} />
                                <Route path="/users/add-new" exact component={AddUser} />
                                <Route path="/users/edit/:id" exact component={AddUser} />
                                <Route path="/blogs" exact component={Blogs} />
                                <Route path="/blogs/add-new" exact component={AddBlog} />
                                <Route path="/blogs/edit/:id" exact component={AddBlog} />
                            </div>

                            <AppFooter layoutColorMode={layoutColorMode} />
                        </div>
                    )}

                    {isLoggedIn && sessionInformation.role_id === 2 && (
                        <div className="layout-main-container">
                            <div className="layout-main">
                                <Route path="/" exact component={Dashboard} />
                                <Route path="/dashboard" exact component={Dashboard} />
                                <Route path="/formlayout" exact component={FormLayoutDemo} />
                                <Route path="/input" exact component={InputDemo} />
                                <Route path="/floatlabel" exact component={FloatLabelDemo} />
                                <Route path="/invalidstate" exact component={InvalidStateDemo} />
                                <Route path="/button" exact component={ButtonDemo} />
                                <Route path="/table" exact component={TableDemo} />
                                <Route path="/list" exact component={ListDemo} />
                                <Route path="/tree" exact component={TreeDemo} />
                                <Route path="/panel" exact component={PanelDemo} />
                                <Route path="/overlay" exact component={OverlayDemo} />
                                <Route path="/menu" exact component={MenuDemo} />
                                <Route path="/messages" exact component={MessagesDemo} />
                                <Route path="/file" exact component={FileDemo} />
                                <Route path="/chart" exact component={ChartDemo} />
                                <Route path="/misc" exact component={MiscDemo} />
                                <Route path="/timeline" exact component={TimelineDemo} />
                                <Route path="/crud" exact component={Crud} />
                                <Route path="/empty" exact component={EmptyPage} />
                                <Route path="/documentation" exact component={Documentation} />
                                <Route path="/blogs" exact component={Blogs} />
                                <Route path="/blogs/add-new" exact component={AddBlog} />
                                <Route path="/blogs/edit/:id" exact component={AddBlog} />
                            </div>

                            <AppFooter layoutColorMode={layoutColorMode} />
                        </div>
                    )}
                </div>
                <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange} layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

                <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                    <div className="layout-mask p-component-overlay"></div>
                </CSSTransition>
            </div>
        </Fragment>
    );
};

export default App;
