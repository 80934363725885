import { MOCK_USERS } from "mocks/mock";
import RestClient from "RestClient";
const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const restClient = new RestClient();

export const doLogin = async (payload) => {
    const result = await restClient.post(`${BASE_URL}/portalusers/authenticate`, payload);
    const { data, status } = result;
    if (status) {
        return data;
    } else {
        // return { status: false, message: data?.message };
        return result;
    }
};

export const getAllSkillSet = async () => {
    const result = await restClient.get(`${BASE_URL}/users/getAllSkills`);
    const { data, status } = result;
    if (status) {
        return data;
    } else {
        // return { status: false, message: data?.message };
        return result;
    }
};

export const checkIfLoggedIn = () => {
    const result = sessionStorage.getItem("userInfo");
    if (result) {
        return result;
    }
    return false;
};


export const doLogout = () => {
    sessionStorage.clear();
    return true;
};
