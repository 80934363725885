import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";
const ConfirmationDialogBox = ({ handleCancelConfirmation, handleConfirmation, displayConfirmation, headerContent, mainContent }) => {
    console.log(mainContent);
    const confirmationDialogFooter = (
        <>
            <Button type="button" label="No" icon="pi pi-times" onClick={handleCancelConfirmation} className="p-button-text" />
            <Button type="button" label="Yes" icon="pi pi-check" onClick={handleConfirmation} className="p-button-text" autoFocus />
        </>
    );
    return (
        <Dialog header={headerContent ? headerContent : "Delete Confirmation"} visible={displayConfirmation} onHide={handleCancelConfirmation} style={{ width: "350px" }} modal footer={confirmationDialogFooter}>
            <div className="flex align-items-center justify-content-center">
                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                <span>{mainContent ? mainContent : "Are you sure you want to delete?"}</span>
            </div>
        </Dialog>
    );
};

export default ConfirmationDialogBox;
